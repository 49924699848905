///////////////////////////////////////////////////////////////////////////////////////////////////////

//  見出しクラス

///////////////////////////////////////////////////////////////////////////////////////////////////////

.c-ttl {
  //h2見出し
  &-2 {
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 30px;
    @include mq-max(xs) {
      font-size: 28px;
      margin-bottom: 20px;
    }
    &>small {
      display: inline-block;
      font-size: 18px;
      font-weight: 600;
      color: $keyColor;
      letter-spacing: 0.06em;
      margin-bottom: 5px;
      position: relative;
      margin-left: 35px;
      &:before {
        content: '';
        display: inline-block;
        background-image: url(/inc/image/common/ico_truck.png);
        position: absolute;
        width: 30px;
        height: 23px;
        top: 5px;
        left: -35px;
      }
      &:after {
        content: '';
        display: inline-block;
        width: 100%;
        height: 2px;
        border-bottom: 2px solid $keyColor;
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }
    &--icontop {
      text-align: center;
      margin-bottom: 30px;
      &>small {
        margin-left: 0;
        &:before {
          top: -20px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
    &--noicon {
      &>small {
        margin-left: 0;
        color: $keyColor;
        &:before {
          content: none;
        }
        &:after {
          content: none;
        }
      }
    }
    &--line {
      padding-right: 70px;
      margin-bottom: 30px;
      @include mq-max(xs) {
        padding-right: 0;
      }
      &>span {
        display: inline-block;
        position: relative;
        &:after {
          content: '';
          display: inline-block;
          border-bottom: 2px solid $keyColor;
          height: 2px;
          width: 50px;
          position: absolute;
          bottom: -9px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
      &--left {
        &>span {
          &:after {
            left: 0;
            transform: none;
          }
        }
      }
      // &--mbLarge {
      //   margin-bottom: 50px;
      // }
    }
  }

  //h3見出し
  &-3 {
    font-size: 24px;
    margin-bottom: 10px;
    font-weight: 500;
    @include mq-max(xs) {
      font-size: 20px;
    }
    &--line {
      position: relative;
      padding-left: 12px;
      &:before,
      &:after {
        content: '';
        display: inline-block;
        width: 2px;
        position: absolute;
        left: 0;
      }
      &:before {
        background-color: $gray;
        height: 100%;
        top: 50%;
        transform: translateY(-50%);
      }
      &:after {
        background-color: $keyColor;
        top: 0;
        height: 50%;
      }
    }
  }

  //modify
  &--center {
    text-align: center;
  }
  &--mbLarge {
    margin-bottom: 50px;
  }
}
