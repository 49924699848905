//引用
.c-blockquote {
  padding: 28px 24px 28px 72px;
  background-color: $lightGray;
  position: relative;
  margin-bottom: 30px;
  &:last-child {
    margin-bottom: 0;
  }
  &:before {
    content: '';
    width: 20px;
    height: 21px;
    display: block;
    background: url('/inc/image/common/ico_blockquote.png')no-repeat center / contain;
    position: absolute;
    top: 28px;
    left: 30px;
  }
  p {
    line-height: (28 / 16);
  }
}