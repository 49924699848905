//アニメーション

//TOP メインヴィジュアル　スクロール
.c-anime {
  &--scroll {
    &:before {
      animation: scrollBar 3s linear infinite;
    }
  }
}


//keyframes
@keyframes scrollBar {
  0% {
    left: -50%;
  }
  100% {
    left: 100%;
  }
}