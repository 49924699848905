//サイトレイアウト構造を記述

#container {}
#main {}
#contents {
  padding: 60px 0 0;
  @include mq-max(content) {
    padding: 60px 0 0;
  }
  @include mq-max(sm) {
    width: $full-size;
  }
}

//gridlex調整
[class*="grid"] {
  margin-bottom: -1 * $contentPadding; //gridlexを使うことでできてしまう下余白を相殺。
}
[class*="col-"] {
  img {
    width: 100%;
    display: block;
  }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////

//  基本ブロックレイアウト

///////////////////////////////////////////////////////////////////////////////////////////////////////
.l-section {
  width: 100%;
  max-width: $contentWidth;
  margin: 0 auto;
  padding: 60px 0;
  @media screen and (max-width:$contentWidth + $contentPadding) {
    padding: 0 $contentPadding 80px;
  }
  @include mq-max(sm) {
    padding: 0 $contentPadding/2 80px;//お好みで変更
  }
  &__inner {
    padding: 0 0 60px;//お好みで変更
    &:last-child {
      padding-bottom: 0;
    }
  }
  &--pbLarge {
    padding-bottom: 120px;
    @include mq-max(sm) {
      padding-bottom: 80px;
    }
  }
  &--full {
    max-width: 100%;
  }
  &--md {
    max-width: 990px;
    margin: 0 auto;
    @include mq-max(xs) {
      max-width: 100%;
    }
  }
  &--sm {
    max-width: 800px;
    margin: 0 auto;
    @include mq-max(xs) {
      max-width: 100%;
    }
  }
  &--xs {
    max-width: 514px;
    margin: 0 auto;
    @include mq-max(xs) {
      max-width: 100%;
    }
    &--left {
      margin: 0;
    }
  }

  //grid調整(デフォで付く下余白をマージンで相殺)
  [class*="grid"] {
    margin-bottom: -1 * $contentPadding;
  }

  &--lightgray {
    background-color: $lightGray-2;
    padding: 120px 0;
    margin-top: 60px;
    margin-bottom: 60px;
    @media screen and (max-width:$contentWidth + $contentPadding) {
      padding: 120px $contentPadding;
      margin-top: 0;
    }
    @include mq-max(sm) {
      padding: 60px $contentPadding;
    }
    @include mq-max(xs) {
      padding: 60px 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
    .l-section {
      &:last-child {
        @include mq-max(content) {
          padding-bottom: 0;
        }
      }
    }

    &--band {
      background: linear-gradient(to right,$lightGray-2 calc(100% - 83px), #fff calc(100% - 83px));
      padding-top: 60px;
      padding-bottom: 60px;
      position: relative;
      @include mq-max(bp1350) {
        padding-right: 83px + 30px;
        padding-left: 30px;
      }
      @include mq-max(content) {
        margin-bottom: 80px !important;
      }
      @include mq-max(xs) {
        background: linear-gradient(to right,$lightGray-2 calc(100% - 30px), #fff calc(100% - 30px));
        padding-left: 0;
        padding-right: 45px;
      }
      &:after {
        content: '';
        width: 10px;
        height: 100%;
        display: inline-block;
        position: absolute;
        right: 75px;
        top: 0;
        background-color: $lightGray-2;
        border-radius: 0 10px 10px 0;
        @include mq-max(xs) {
          right: 22px;
        }
      }
      &:last-child {
        margin-bottom: 60px;
      }
      .l-section {
        padding-top: 0;
        &:last-child {
          padding-bottom: 0;
        }
        @include mq-max(content) {
          padding: 0 $contentPadding 80px;
        }
        @include mq-max(sm) {
          padding: 0 $contentPadding/2 80px;//お好みで変更
        }
      }
    }
    &--last {
      margin-bottom: 0;
    }
    .l-section {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  &--lightpink {
    background-color: $lightpink;
    padding: 120px 0;
    margin-top: 60px;
    margin-bottom: 60px;
    @media screen and (max-width:$contentWidth + $contentPadding) {
      padding: 120px $contentPadding;
      margin-top: 0;
    }
    @include mq-max(sm) {
      padding: 60px $contentPadding;
    }
    @include mq-max(xs) {
      padding: 60px 0;
    }
    &:last-child {
      margin-bottom: 0;
    }

    .l-section {
      &:last-child {
        @include mq-max(content) {
          padding-bottom: 0;
        }
      }
    }

    &--band {
      background: linear-gradient(to right,$lightpink calc(100% - 83px), #fff calc(100% - 83px));
      padding-top: 60px;
      padding-bottom: 60px;
      position: relative;
      @include mq-max(bp1350) {
        padding-right: 73px + 30px;
        padding-left: 30px;
      }
      @include mq-max(content) {
        margin-bottom: 80px !important;
      }
      @include mq-max(xs) {
        background: linear-gradient(to right,$lightpink calc(100% - 30px), #fff calc(100% - 30px));
        padding-left: 15px;
        padding-right: 45px;
        margin-bottom: 60px !important;
      }
      &:after {
        content: '';
        width: 10px;
        height: 100%;
        display: inline-block;
        position: absolute;
        right: 75px;
        top: 0;
        background-color: $lightpink;
        border-radius: 0 10px 10px 0;
        @include mq-max(xs) {
          right: 22px;
        }
      }
      &:last-child {
        margin-bottom: 60px;
      }
      .l-section {
        padding-top: 0;
        &:last-child {
          padding-bottom: 0;
        }
        @include mq-max(content) {
          padding: 0 $contentPadding 80px;
        }
        @include mq-max(sm) {
          padding: 0 $contentPadding/2 80px;//お好みで変更
        }
      }
    }
  }


  &--lowPadding {
    padding: 60px 0;
    .l-section {
      padding-top: 0;
      &:last-child {
        padding-bottom: 0;
      }
      @include mq-max(content) {
        padding: 0 $contentPadding 80px;
      }
      @include mq-max(sm) {
        padding: 0 $contentPadding/2 80px;//お好みで変更
      }
    }
  }
}

//background full width
@mixin bg_scss() {
  @include mq-max(content) {
    margin-bottom: 60px;;
  }
  &>.l-section {
    @include mq-max(content) {
      padding-top: 60px;
    }
  }
}

.l-section--keyColor {
  background-color: $keyColor;
  @include bg_scss();
}


///////////////////////////////////////////////////////////////////////////////////////////////////////

//  text-align

///////////////////////////////////////////////////////////////////////////////////////////////////////
.u-taL {
  text-align: left !important;
}
.u-taC {
  text-align: center !important;
}
.u-taR {
  text-align: right !important;
}

///////////////////////////////////////////////////////////////////////////////////////////////////////

//  only系

///////////////////////////////////////////////////////////////////////////////////////////////////////
.u-only-xs {
  display: none !important;
  @include mq-max(xs) {
    display: block !important;
  }
}
.u-only-sm {
  display: none !important;
  @include mq-max(sm) {
    display: block !important;
  }
  @include mq-max(xs) {
    display: none !important;
  }
}
.u-only-md {
  display: none !important;
  @include mq-max(md) {
    display: block !important;
  }
  @include mq-max(sm) {
    display: none !important;
  }
}
.u-only-lg {
  @include mq-max(md) {
    display: none !important;
  }
}

.u-not-under-sm {
  @include mq-max(sm) {
    display: none;
  }
}

.u-only-sm-xs {
  display: none;
  @include mq-max(sm) {
    display: block;
  }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////

//  ページ内アンカー位置調整

///////////////////////////////////////////////////////////////////////////////////////////////////////
// .anchorReposition {
//   padding-top: $headerHeight + 10px;
//   margin-top: -1 * $headerHeight - 10px;
//   @include mq-max(md) {
//     padding-top: $headerHeight-sp + 10px;
//     margin-top: -1 * $headerHeight-sp - 10px;
//   }
// }

///////////////////////////////////////////////////////////////////////////////////////////////////////

//  layout

///////////////////////////////////////////////////////////////////////////////////////////////////////

//互い違い
.l-alternate {
//  margin-bottom: 0 + (-1 * $contentPadding);
  &__wrap {
    margin-bottom: 60px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}


//2カラムレイアウト
.l-col2 {
  position: relative;
  //画像端付き
  &--side {
    overflow: hidden;
    .l-col2__img {
      width: calc(100% / 2 - 22px);
      height: 510px;
      flex: 0 0 calc(100% / 2 - 22px);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 0 10px 10px 0;
      @include mq-max(sm) {
        border-radius: 0;
      }
      @include mq-max(sm) {
        flex: initial;
        width: 100%;
        height: 500px;
      }
      @include mq-max(xs) {
        height: 300px;
      }
    }
    .l-col2__detail {
      padding: 0 0 0 50px;
      max-width: 552px;
      width: calc(100% / 2 + 22px);
      flex: 0 0 calc(100% / 2 + 22px);
      @include mq-max(content) {
        padding-right: $contentPadding;
        padding-left: 30px;
      }
      @include mq-max(sm) {
        padding: 0 $contentPadding / 2;
        max-width: 100%;
        width: 100%;
      }
    }
    .l-col2__block {
      &--reverse {
        .l-col2__img {
          border-radius: 10px 0 0 10px;
          @include mq-max(sm) {
            border-radius: 0;
          }
        }
        .l-col2__detail {
          padding: 0 50px 0 0;
          @include mq-max(content) {
            padding-right: 30px;
            padding-left: $contentPadding;
          }
          @include mq-max(sm) {
            padding-left: $contentPadding / 2;
          }
        }
      }
    }

    //パーツ調整
    .l-section {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__img {
    width: 50%;
    @include mq-max(sm) {
      width: 100%;
      margin-bottom: 30px;
    }
    img {
      display: block;
      width: 100%;
      border-radius: $border-radius;
      @include mq-max(sm) {
        max-width: 100%;
        margin: 0 auto;
      }
    }
  }
  &__detail {
    padding: 0 0 0 50px;
    width: 50%;
    @include mq-max(sm) {
      width: 100%;
      padding: 0;
    }
  }
  &__block {
    display: flex;
    align-items: center;
    padding-bottom: 120px;
    @include mq-max(sm) {
      flex-direction: column;
      padding-bottom: 60px;
    }
    &:last-child {
      padding-bottom: 0;
    }
    &--reverse {
      flex-direction: row-reverse;
      @include mq-max(sm) {
        flex-direction: column;
      }
      .l-col2__detail {
        padding: 0 50px 0 0;
        @include mq-max(sm) {
          padding: 0;
        }
      }
    }
  }
  &__btn {
    @include mq-max(sm) {
      text-align: center;
    }
  }

  // こんな困りごとありませんか？
  &--komarigoto {
    .l-col2__block {
      align-items: unset;
    }
    .l-col2__detail {
      background-color: $lightpink;
      padding: 50px 50px !important;
      border-radius: 10px 0 0 10px;
      @include mq-max(sm) {
        padding: 50px 30px !important;
      }
      .c-ttl-2 {
        margin-bottom: 30px;
      }
    }
    .l-col2__img {
      // height: auto;
      border-radius: 0 !important;
      border-radius: 0 10px 10px 0;
      @include mq-max(sm) {
        height: 250px;
        margin-bottom: 0;
      }
    }
  }
}

// 配送ネットワーク
.l-network {
  position: relative;
  &__textarea {
    position: absolute;
    top: 220px;
    left: 0;
    width: 554px;
    @include mq-max(lg) {
      padding: 0 15px;
      top: 100px;
    }
    @include mq-max(md) {
      width: auto;
      position: static;
    }
  }
  &__imgarea {
    text-align: center;
    img {
      max-width: 100%;
    }
  }
}

.p-komarigoto {
  padding: 60px 0;
  max-width: 100%;
  overflow: hidden;
  @include mq-max(md) {
    padding-top: 0;
    margin-bottom: 50px;
  }
  .grid {
    @include mq-max(md) {
      display: block;
    }
  }
  .col-6 {
    @include mq-max(md) {
      max-width: 100%;
    }
  }
}
.p-komarigoto__inner {

.grid {
  flex-wrap: nowrap;
  @include mq-max(md) {
    flex-wrap: wrap;
  }
}

  .p-komarigoto__txt {
    padding: 0;
    order: 1;
    &__inner {
      background-color: $lightpink;
      max-width: 552px;
      // width: calc(100% / 2 + 22px);
      margin-left: auto;
      height: 100%;
      padding: 50px 30px 50px 50px;
      border-radius: 10px 0 0 10px;
      @include mq-max(md) {
        max-width: 100%;
        margin-left: 0;
        padding: 50px 90px;
      }
      @include mq-max(xs) {
        padding: 50px 50px;
      }
    }
  }
  .p-komarigoto__img {
    order: 2;
    padding: 0;
    @include mq-max(md) {
      height: 300px;
    }

    &__inner {
      background-size: cover;
      background-position: center;
      height: 100%;
    }
  }
}
