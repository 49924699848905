
.c-box {
  background: $white;
  border-radius: $border-radius;
  padding: 30px 10px 30px 20px;
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0
  }
}
